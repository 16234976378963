import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { Input, Spinner } from 'reactstrap';

import TableContainer from '../../Components/Common/TableContainerReactTable';
import { getAgents, setNotificationStatus } from '../../slices/thunks';

const AlertTable = () => {
    const dispatch: any = useDispatch();

    const agentsData = createSelector(
        (state: any) => state.Agent,
        (state) => ({
            agents: state.agents,
            loading: state.loading,
            notificationStatLoading: state.notificationStatLoading,
        })
    );

    const { agents, loading, notificationStatLoading } =
        useSelector(agentsData);

    useEffect(() => {
        dispatch(getAgents());
    }, [dispatch]);

    const columns = useMemo(
        () => [
            {
                header: 'ID',
                cell: (cell: any) => {
                    return (
                        <Link
                            to={`/agent/${cell.getValue()}`}
                            className="fw-medium"
                        >
                            {cell.getValue()}
                            {` `}
                            {cell.getValue() ==
                            process.env.REACT_APP_TEST_AGENT_ID ? (
                                <span className="badge bg-primary-subtle text-primary">
                                    {`Test`}
                                </span>
                            ) : (
                                ''
                            )}
                        </Link>
                    );
                },
                accessorKey: 'id',
                enableColumnFilter: false,
            },
            {
                header: 'Agent',
                enableColumnFilter: false,
                accessorKey: 'agentStatus',
                cell: (cell: any) => {
                    switch (cell.getValue()) {
                        case true:
                            return (
                                <span className="badge bg-success-subtle text-success text-uppercase">
                                    {' ONLINE '}
                                </span>
                            );
                        case false:
                            return (
                                <span className="badge bg-danger-subtle  text-danger text-uppercase">
                                    {' OFFLINE '}
                                </span>
                            );
                    }
                },
            },
            {
                header: 'Alert Time',
                enableColumnFilter: false,
                accessorKey: 'alertTime',
                cell: (cell: any) => {
                    const time = cell.getValue();

                    return (
                        <span>
                            {' '}
                            {time && Array.isArray(time) && time.length
                                ? time[0].join(' - ')
                                : ''}
                        </span>
                    );
                },
            },
            {
                header: 'Alerts',
                enableColumnFilter: false,
                cell: (cell: any) => {
                    return (
                        <Link
                            to={`/agent/${cell.row.original.id}/alerts`}
                            className={`btn btn-sm btn-outline-danger shadow-none ${cell.row.original.agentStatus ? '' : 'disabled'}`}
                        >
                            Show
                        </Link>
                    );
                },
            },
            {
                header: 'Notification',
                enableColumnFilter: false,
                accessorKey: 'notification',
                cell: (cell: any) => {
                    return (
                        <div
                            className="form-check form-switch form-switch-success form-switch-md"
                            dir="ltr"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                disabled={
                                    notificationStatLoading ||
                                    !cell.row.original.agentStatus ||
                                    cell.row.original.id ==
                                        process.env.REACT_APP_TEST_AGENT_ID
                                }
                                checked={cell.getValue() ?? false}
                                onChange={(event) => {
                                    dispatch(
                                        setNotificationStatus({
                                            agentId: cell.row.original.id,
                                            status: event.target.checked,
                                        })
                                    );
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                header: 'Last Alert Time',
                accessorKey: 'lastAlert',
                enableColumnFilter: false,
            },
            {
                header: 'User',
                enableColumnFilter: false,
                accessorKey: 'userStatus',
                cell: (cell: any) => {
                    switch (cell.getValue()) {
                        case true:
                            return (
                                <span className="badge bg-success-subtle text-success text-uppercase">
                                    {' ONLINE '}
                                </span>
                            );
                        case false:
                            return (
                                <span className="badge bg-danger-subtle  text-danger text-uppercase">
                                    {' OFFLINE '}
                                </span>
                            );
                    }
                },
            },
            {
                header: 'Last User Online',
                accessorKey: 'lastUserOffline',
                enableColumnFilter: false,
            },
        ],
        [dispatch, notificationStatLoading]
    );

    return (
        <React.Fragment>
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : (
                <TableContainer
                    columns={columns || []}
                    data={agents || []}
                    isGlobalFilter={true}
                    customPageSize={100}
                    divClass="table-responsive"
                    tableClass="table-sm table-centered align-middle table-nowrap mb-0"
                    theadClass="text-muted table-light"
                    SearchPlaceholder="Search..."
                />
            )}
        </React.Fragment>
    );
};

export { AlertTable };
