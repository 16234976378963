//Include Both Helper File with needed methods
import {
    postFakeLogin,
    postJwtLogin,
} from '../../../helpers/fakebackend_helper';

import {
    loginSuccess,
    logoutUserSuccess,
    apiError,
    reset_login_flag,
} from './reducer';

export const loginUser = (user: any, history: any) => async (dispatch: any) => {
    try {
        let response;
        if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
            response = postJwtLogin({
                email: user.email,
                password: user.password,
            });
        } else if (process.env.REACT_APP_API_URL) {
            response = postFakeLogin({
                email: user.email,
                password: user.password,
            });
        }

        let data = await response;

        if (data) {
            sessionStorage.setItem('authUser', JSON.stringify(data));

            if (process.env.REACT_APP_DEFAULTAUTH === 'fake') {
                let finallogin: any = JSON.stringify(data);
                finallogin = JSON.parse(finallogin);
                data = finallogin.data;
                if (finallogin.status === 'success') {
                    dispatch(loginSuccess(data));
                    history('/dashboard');
                } else {
                    dispatch(apiError(finallogin));
                }
            } else {
                dispatch(loginSuccess(data));
                history('/dashboard');
            }
        }
    } catch (error: any) {
        dispatch(apiError(error));
    }
};

export const logoutUser = () => async (dispatch: any) => {
    try {
        sessionStorage.removeItem('authUser');
        dispatch(logoutUserSuccess());
    } catch (error: any) {
        dispatch(apiError(error));
    }
};

export const resetLoginFlag = () => async (dispatch: any) => {
    try {
        const response = dispatch(reset_login_flag());
        return response;
    } catch (error: any) {
        dispatch(apiError(error));
    }
};
