import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

//import Components
import Header from './Header';
import Sidebar from './Sidebar';
// import Footer from './Footer';

//import actions
import {
    changeLayout,
    changeLayoutMode,
    changeSidebarVisibility,
    changeShowAlert,
} from '../slices/thunks';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import withRouter from '../Components/Common/withRouter';
import StatusBar from '../Components/Common/StatusBar';
import AlertViewModal from '../Components/Common/AlertViewModal';

const Layout = (props: any) => {
    const dispatch: any = useDispatch();
    const selectLayoutState = (state: any) => state.Layout;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            layoutType: layout.layoutType,
            layoutModeType: layout.layoutModeType,
            leftsidbarSizeType: layout.leftsidbarSizeType,
            preloader: layout.preloader,
            sidebarVisibilitytype: layout.sidebarVisibilitytype,
        })
    );
    const wsData = createSelector(
        (state: any) => state.WebSocket,
        (state) => ({
            status: state.status,
        })
    );
    const alertData = createSelector(
        (state: any) => state.Alert,
        (state) => ({
            alert: state.newAlert,
            showAlert: state.showAlert,
        })
    );

    // Inside your component
    const {
        layoutType,
        layoutModeType,
        leftsidbarSizeType,
        // preloader,
        sidebarVisibilitytype,
    } = useSelector(selectLayoutProperties);

    const { status } = useSelector(wsData);
    const { alert, showAlert } = useSelector(alertData);

    /*
    layout settings
    */
    useEffect(() => {
        if (
            layoutType ||
            layoutModeType ||
            leftsidbarSizeType ||
            sidebarVisibilitytype
        ) {
            window.dispatchEvent(new Event('resize'));
            dispatch(changeLayoutMode(layoutModeType));
            dispatch(changeLayout(layoutType));
            dispatch(changeSidebarVisibility(sidebarVisibilitytype));
        }
    }, [
        layoutType,
        layoutModeType,
        leftsidbarSizeType,
        sidebarVisibilitytype,
        dispatch,
    ]);
    /*
    call dark/light mode
    */
    const onChangeLayoutMode = (value: any) => {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value));
        }
    };

    const [headerClass, setHeaderClass] = useState<any>('');

    function scrollNavigation() {
        const scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setHeaderClass('topbar-shadow');
        } else {
            setHeaderClass('');
        }
    }

    // class add remove in header
    useEffect(() => {
        window.addEventListener('scroll', scrollNavigation, true);
    });

    useEffect(() => {
        const humberIcon = document.querySelector(
            '.hamburger-icon'
        ) as HTMLElement;
        if (sidebarVisibilitytype === 'show' || layoutType === 'vertical') {
            humberIcon.classList.remove('open');
        } else {
            humberIcon && humberIcon.classList.add('open');
        }
    }, [sidebarVisibilitytype, layoutType]);

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <StatusBar
                    status={status === 'connected'}
                    text={
                        status === 'connected'
                            ? 'Connected'
                            : 'Connection not established'
                    }
                />
                <Header
                    headerClass={headerClass}
                    layoutModeType={layoutModeType}
                    onChangeLayoutMode={onChangeLayoutMode}
                />
                <Sidebar layoutType={layoutType} />
                <AlertViewModal
                    isOpen={showAlert}
                    title={alert.title}
                    image={alert.image}
                    desc={alert.agent}
                    setToggle={(stat: any) => dispatch(changeShowAlert(stat))}
                />
                <div className="main-content">
                    {props.children}
                    {/* <Footer /> */}
                </div>
            </div>
        </React.Fragment>
    );
};

Layout.propTypes = {
    children: PropTypes.object,
};

export default withRouter(Layout);
