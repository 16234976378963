import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAlerts as getAlertsApi } from '../../helpers/backend_helper';

import { changeShowAlertAction } from './reducer';

export const getAlerts = createAsyncThunk(
    'agent/getAlerts',
    async ({ agentId, afterId = '', alertType = '', sortBy = '' }: any) => {
        try {
            const response = await getAlertsApi(
                agentId,
                afterId,
                alertType,
                sortBy
            );
            return { id: agentId, alerts: response };
        } catch (error) {
            return error;
        }
    }
);

export const getMoreAlerts = createAsyncThunk(
    'agent/getMoreAlerts',
    async ({ agentId }: any, thunkAPI) => {
        try {
            const state: any = thunkAPI.getState();
            const response = await getAlertsApi(
                agentId,
                `<${state.Alert.afterId}`
            );
            return { id: agentId, alerts: response };
        } catch (error) {
            return error;
        }
    }
);

export const changeShowAlert = (status: any) => async (dispatch: any) => {
    try {
        dispatch(changeShowAlertAction(status));
    } catch (error) {
        console.log(error);
    }
};
