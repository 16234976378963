import { v4 as uuidv4 } from 'uuid';

// action
import { connected, disconnected, errorOccurred } from './reducer';

import { updateAgent } from '../agent/reducer';
import { newAlert } from '../alert/reducer';

let clientId: string | null = null;
let socket: WebSocket | null = null;
let reconnectInterval: any = null;
let isWsConnecting = false; // To avoid multiple connection calls
let isWsDisconnecting = false;

const partMessages: Map<string, PartMessage> = new Map();

class PartMessage {
    totalParts: number;
    finalMessage: string;
    parts: Map<number, string>;

    public constructor(totalParts: number) {
        this.totalParts = totalParts;
        this.finalMessage = '';
        this.parts = new Map();
    }
}

const partMessageHandler = (message: any): any | null => {
    let messageId = '';
    let partMessage: PartMessage | undefined;

    if (message.parts) {
        messageId = message.messageId;
        partMessage = partMessages.get(messageId);

        if (!partMessage) {
            partMessages.set(messageId, new PartMessage(message.parts));
            return null;
        } else {
            partMessage.totalParts = message.parts;
        }
    } else if (message.part) {
        messageId = message.messageId;
        partMessage = partMessages.get(messageId);

        if (!partMessage) {
            partMessages.set(messageId, new PartMessage(0));
            partMessage = partMessages.get(messageId);
        }

        partMessage!.parts.set(message.part, message.data);
    } else {
        return message;
    }

    if (partMessage!.parts.size == partMessage!.totalParts) {
        try {
            const sortedMap = new Map([...partMessage!.parts.entries()].sort());

            for (const v of sortedMap.values()) {
                partMessage!.finalMessage += v;
            }

            delete message.data;
            return { ...message, ...JSON.parse(partMessage!.finalMessage) };
        } catch (e) {
            console.error('[Error]: Message parts could be corrupted: $e');
        } finally {
            partMessages.delete(messageId);
        }
    }

    return null;
};

export const sendMessage = (message: any) => () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(message));
    }
};

const handleReceivedMessage = (dispatch: any, data: any) => {
    switch (data.type) {
        case 'agent-data':
            dispatch(updateAgent(data));
            break;

        case 'alert':
            dispatch(newAlert(data));
            break;

        default:
            break;
    }
};

const handleWebSocketMessage = (dispatch: any, data: any) => {
    switch (data.event) {
        case 'ping':
            dispatch(sendMessage({ event: 'ping' }));
            break;

        case 'connected':
            dispatch(sendMessage({ event: 'ping' }));

            clientId = localStorage.getItem('clientId');

            if (clientId === null) {
                clientId = uuidv4();
                localStorage.setItem('clientId', clientId!);
            }

            dispatch(
                sendMessage({
                    event: 'admin-join',
                    clientId: clientId,
                    clientType: 'admin',
                })
            );
            break;

        case 'message':
            const message = partMessageHandler(data);
            if (message != null) {
                handleReceivedMessage(dispatch, data);
            }
            break;

        case 'error':
            console.log(data['reason']);
            break;

        default:
            break;
    }
};

export const connectWebSocket = (url: string) => (dispatch: any) => {
    if (isWsConnecting) {
        return;
    }

    isWsConnecting = true;

    if (reconnectInterval) {
        clearInterval(reconnectInterval);
    }

    socket = new WebSocket(url);

    socket.onopen = () => {
        dispatch(connected());

        if (reconnectInterval) {
            clearInterval(reconnectInterval);
            reconnectInterval = null;
        }

        isWsConnecting = false;
    };

    socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleWebSocketMessage(dispatch, data);
    };

    socket.onclose = () => {
        dispatch(disconnected());

        if (!reconnectInterval) {
            reconnectInterval = setInterval(() => {
                dispatch(connectWebSocket(url));
            }, 5000);
        }

        isWsConnecting = false;
    };

    socket.onerror = (error: any) => {
        dispatch(errorOccurred(error.message));

        if (socket) {
            socket.close();
        }

        if (reconnectInterval) {
            clearInterval(reconnectInterval);
            reconnectInterval = null;
        }

        reconnectInterval = setInterval(() => {
            dispatch(connectWebSocket(url));
        }, 5000);

        isWsConnecting = false;
    };
};

export const disconnectWebSocket = () => () => {
    if (isWsDisconnecting) {
        return;
    }

    isWsDisconnecting = true;

    if (socket) {
        socket.close();
    }

    if (reconnectInterval) {
        clearInterval(reconnectInterval);
        reconnectInterval = null;
    }

    isWsDisconnecting = false;
};
