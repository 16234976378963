import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import TableContainer from '../../Components/Common/TableContainerReactTable';
import { getAgents } from '../../slices/thunks';

import AgentQRModal from './AgentQRModal';

const AgentTable = () => {
    const dispatch: any = useDispatch();

    const agentsData = createSelector(
        (state: any) => state.Agent,
        (state) => ({
            agents: state.agents,
            loading: state.loading,
        })
    );

    const { agents, loading } = useSelector(agentsData);
    const [isQRModalOpen, setIsQRModalOpen] = useState(false);
    const [selectedQRValue, setSelectedQRValue] = useState('');

    const toggleQRModalWithValue = useCallback(
        (value: any) => {
            value = value.replace(/-/g, ''); // Remove hyphens in the uuid
            setSelectedQRValue(value);
            setIsQRModalOpen(!isQRModalOpen);
        },
        [isQRModalOpen]
    );

    const toggleQRModal = () => setIsQRModalOpen(!isQRModalOpen);

    useEffect(() => {
        dispatch(getAgents());
    }, [dispatch]);

    const columns = useMemo(
        () => [
            {
                header: 'ID',
                cell: (cell: any) => {
                    return (
                        <Link
                            to={`/agent/${cell.getValue()}`}
                            className="fw-medium"
                        >
                            {cell.getValue()}
                            {` `}
                            {cell.getValue() ==
                            process.env.REACT_APP_TEST_AGENT_ID ? (
                                <span className="badge bg-primary-subtle text-primary">
                                    {`Test`}
                                </span>
                            ) : (
                                ''
                            )}
                        </Link>
                    );
                },
                accessorKey: 'id',
                enableColumnFilter: false,
            },
            {
                header: 'Last Offline Time',
                accessorKey: 'lastOffline',
                enableColumnFilter: false,
            },
            {
                header: 'Installed Date',
                accessorKey: 'createdAt',
                enableColumnFilter: false,
            },
            {
                header: 'Status',
                enableColumnFilter: false,
                accessorKey: 'agentStatus',
                cell: (cell: any) => {
                    switch (cell.getValue()) {
                        case true:
                            return (
                                <span className="badge bg-success-subtle text-success text-uppercase">
                                    {' ONLINE '}
                                </span>
                            );
                        case false:
                            return (
                                <span className="badge bg-danger-subtle  text-danger text-uppercase">
                                    {' OFFLINE '}
                                </span>
                            );
                    }
                },
            },
            {
                header: 'Logs',
                enableColumnFilter: false,
                cell: (cell: any) => {
                    return (
                        <Link
                            to={`/agent/${cell.row.original.id}/logs`}
                            className={`btn btn-sm btn-outline-primary shadow-none ${cell.row.original.agentStatus ? '' : 'disabled'}`}
                        >
                            Show
                        </Link>
                    );
                },
            },
            {
                header: 'QR',
                enableColumnFilter: false,
                cell: (cell: any) => {
                    return (
                        <button
                            type="button"
                            className="btn btn-sm btn-light shadow-none"
                            onClick={() =>
                                toggleQRModalWithValue(cell.row.original.id)
                            }
                        >
                            Show QR
                        </button>
                    );
                },
            },
        ],
        [toggleQRModalWithValue]
    );

    return (
        <React.Fragment>
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : (
                <TableContainer
                    columns={columns || []}
                    data={agents || []}
                    isGlobalFilter={true}
                    customPageSize={8}
                    divClass="table-responsive"
                    tableClass="table-sm table-centered align-middle table-nowrap mb-0"
                    theadClass="text-muted table-light"
                    SearchPlaceholder="Search..."
                />
            )}
            <AgentQRModal
                value={selectedQRValue}
                isOpen={isQRModalOpen}
                toggle={toggleQRModal}
            />
        </React.Fragment>
    );
};

export { AgentTable };
