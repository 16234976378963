const layoutTypes = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
};

const layoutModeTypes = {
    LIGHTMODE: 'light',
    DARKMODE: 'dark',
};

const leftsidbarSizeTypes = {
    DEFAULT: 'lg',
    COMPACT: 'md',
    SMALLICON: 'sm',
    SMALLHOVER: 'sm-hover',
};

const leftsidebarHover = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
};

const preloaderTypes = {
    ENABLE: 'enable',
    DISABLE: 'disable',
};

const sidebarVisibilitytypes = {
    SHOW: 'show',
    HIDDEN: 'hidden',
};

export {
    layoutTypes,
    layoutModeTypes,
    leftsidbarSizeTypes,
    leftsidebarHover,
    preloaderTypes,
    sidebarVisibilitytypes,
};
