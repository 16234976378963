import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    getAgents as getAgentsApi,
    setNotificationStatus as setNotificationStatusApi,
} from '../../helpers/backend_helper';

export const getAgents = createAsyncThunk('agent/getAgents', async () => {
    try {
        const response = getAgentsApi();
        return response;
    } catch (error) {
        return error;
    }
});

export const setNotificationStatus = createAsyncThunk(
    'agent/setNotificationStatus',
    async ({ agentId, status }: { agentId: string; status: boolean }) => {
        try {
            const response = setNotificationStatusApi(agentId, status);
            return response;
        } catch (error) {
            return error;
        }
    }
);
