import { createSlice } from '@reduxjs/toolkit';

import { getAgentLogsData, getAgentLogs } from './thunk';

export const initialState = {
    logs: {},
    logsData: [],
    logsLoading: false,
    logsDataLoading: true,
    error: {},
};

const agentLogsSlice = createSlice({
    name: 'AgentLogs',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(getAgentLogsData.pending, (state: any) => {
            state.logs = 'Please select any logs';
            state.logsDataLoading = true;
        });
        builder.addCase(
            getAgentLogsData.fulfilled,
            (state: any, action: any) => {
                state.logsData = action.payload;
                state.logsDataLoading = false;
            }
        );
        builder.addCase(
            getAgentLogsData.rejected,
            (state: any, action: any) => {
                state.error = action.payload || null;
                state.logsDataLoading = false;
            }
        );

        builder.addCase(getAgentLogs.pending, (state: any) => {
            state.logsLoading = true;
        });
        builder.addCase(getAgentLogs.fulfilled, (state: any, action: any) => {
            state.logs = action.payload;
            state.logsLoading = false;
        });
        builder.addCase(getAgentLogs.rejected, (state: any, action: any) => {
            state.error = action.payload || null;
            state.logsLoading = false;
        });
    },
});

export default agentLogsSlice.reducer;
