import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
    Container,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Spinner,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { getAgentLogs, getAgentLogsData } from '../../../slices/thunks';

const AgentLogs = () => {
    const { id } = useParams();
    const dispatch: any = useDispatch();

    const [selectedLogsType, setSelectedLogsType] = useState('app');
    const [selectedLogs, setSelectedLogs] = useState('');

    const agentLogsData = createSelector(
        (state: any) => state.AgentLogs,
        (state) => ({
            logs: state.logs,
            logsData: state.logsData,
            logsLoading: state.logsLoading,
            logsDataLoading: state.logsDataLoading,
        })
    );

    const { logs, logsLoading, logsData, logsDataLoading } =
        useSelector(agentLogsData);

    const handleAgentLogsSelect = (name: string) => {
        setSelectedLogs(name);
        dispatch(
            getAgentLogs({
                agentId: id,
                logsType: selectedLogsType,
                logsName: name,
            })
        );
    };

    useEffect(() => {
        dispatch(getAgentLogsData({ agentId: id, logsType: selectedLogsType }));
    }, [dispatch, id, selectedLogsType]);

    const AppLogDisplay = ({ data }: any) => {
        return (
            <div className="mt-3">
                {data.agent ? (
                    <div>
                        <p>*********************************</p>
                        <p>********** AGENT LOGS *********</p>
                        <p>*********************************</p>
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                            {data.agent}
                        </div>
                    </div>
                ) : (
                    <p>
                        <b>{`>>>>>>>> No Agent Logs <<<<<<<<`}</b>
                    </p>
                )}
                {data.recording ? (
                    <div>
                        <p>*********************************</p>
                        <p>********** RECORDING LOGS *********</p>
                        <p>*********************************</p>
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                            {data.recording}
                        </div>
                    </div>
                ) : (
                    <p>
                        <b>{`>>>>>>>> No Recording Logs <<<<<<<<`}</b>
                    </p>
                )}
                {data.watchdog ? (
                    <div>
                        <p>*********************************</p>
                        <p>********** WATCHDOG LOGS *********</p>
                        <p>*********************************</p>
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                            {data.watchdog}
                        </div>
                    </div>
                ) : (
                    <p>
                        <b>{`>>>>>>>> No Watchdog Logs <<<<<<<<`}</b>
                    </p>
                )}
            </div>
        );
    };

    document.title = 'Agent Logs | MySpy';

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        title="Logs"
                        breadcrumbs={[
                            { title: 'Dashboard', path: '/' },
                            { title: 'Agents', path: '/agents' },
                            { title: id, path: `/agent/${id}` },
                        ]}
                    />
                    <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                        <div className="file-manager-sidebar">
                            <div className="p-3 d-flex flex-column h-100">
                                <Row className="justify-content-between mb-2 align-items-center">
                                    <Col xs="auto">
                                        <div>
                                            <h5>Logs</h5>
                                        </div>
                                    </Col>
                                    <Col xs="auto">
                                        <select
                                            className="form-control"
                                            value={selectedLogsType}
                                            onChange={(e) =>
                                                setSelectedLogsType(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="app">App Log</option>
                                            <option value="system">
                                                System Log
                                            </option>
                                        </select>
                                    </Col>
                                </Row>

                                <div className="search-box mb-2">
                                    <input
                                        type="text"
                                        className="form-control bg-light border-light"
                                        placeholder="Search here..."
                                    />
                                </div>
                                {logsDataLoading ? (
                                    <div className="text-center">
                                        <Spinner
                                            animation="border"
                                            variant="primary"
                                        />
                                    </div>
                                ) : (
                                    <SimpleBar className="mx-n4 px-4 log-menu-sidebar-scroll">
                                        <ListGroup className="grid-list">
                                            {logsData.map(
                                                (
                                                    item: string,
                                                    index: number
                                                ) => (
                                                    <ListGroupItem
                                                        key={index}
                                                        tag="button"
                                                        className={
                                                            selectedLogs == item
                                                                ? 'list-group-item-action active'
                                                                : 'list-group-item-action border'
                                                        }
                                                        onClick={() =>
                                                            handleAgentLogsSelect(
                                                                item
                                                            )
                                                        }
                                                    >
                                                        {item}
                                                    </ListGroupItem>
                                                )
                                            )}
                                        </ListGroup>
                                    </SimpleBar>
                                )}
                            </div>
                        </div>
                        <div className="file-manager-content w-100 p-3 py-0">
                            <Col className="pt-3 pb-2 text-center">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 me-2 d-block d-lg-none">
                                        <button
                                            type="button"
                                            className="btn btn-soft-success btn-icon btn-sm fs-16 file-menu-btn"
                                        >
                                            <i className="ri-menu-2-fill align-bottom"></i>
                                        </button>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0">
                                            {selectedLogsType == 'app'
                                                ? 'App Log'
                                                : 'System Log'}
                                        </h5>
                                    </div>
                                </div>
                            </Col>
                            <div className="mx-n3 pt-4 px-4 file-manager-content-scroll overflow-x-hidden overflow-y-auto">
                                <div className="mb-2">
                                    {logsLoading ? (
                                        <div className="text-center">
                                            <Spinner
                                                animation="border"
                                                variant="primary"
                                            />
                                        </div>
                                    ) : (
                                        <Row>
                                            {selectedLogsType == 'app' ? (
                                                <AppLogDisplay data={logs} />
                                            ) : (
                                                <div
                                                    style={{
                                                        whiteSpace: 'pre-wrap',
                                                    }}
                                                >{`${logs}`}</div>
                                            )}
                                        </Row>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AgentLogs;
