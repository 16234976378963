import { combineReducers } from 'redux';

// Websocket
import WebSocketReducer from './ws/reducer';

// Front
import LayoutReducer from './layouts/reducer';

// Authentication
import LoginReducer from './auth/login/reducer';
import AccountReducer from './auth/register/reducer';
import ForgetPasswordReducer from './auth/forgetpwd/reducer';
import ProfileReducer from './auth/profile/reducer';

// Agent
import AgentReducer from './agent/reducer';
import AgentLogsReducer from './agent/logs/reducer';

// Alert
import AlertReducer from './alert/reducer';

// Customer
import CustomerReducer from './customer/reducer';

// Invoice
import InvoiceReducer from './invoice/reducer';

const rootReducer = combineReducers({
    WebSocket: WebSocketReducer,
    Layout: LayoutReducer,
    Login: LoginReducer,
    Account: AccountReducer,
    ForgetPassword: ForgetPasswordReducer,
    Profile: ProfileReducer,
    Agent: AgentReducer,
    Alert: AlertReducer,
    AgentLogs: AgentLogsReducer,
    Customer: CustomerReducer,
    Invoice: InvoiceReducer,
});

export default rootReducer;
