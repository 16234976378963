import { createSlice } from '@reduxjs/toolkit';

import { getAlerts, getMoreAlerts } from './thunk';

const alertsPerPage = 20;

export const initialState = {
    alerts: [],
    currAlertId: '',
    alertsLoading: true,
    canLoadMore: false,
    afterId: -1,
    newAlert: {},
    showAlert: false,
    error: {},
};

const alertSlice = createSlice({
    name: 'Alert',
    initialState,
    reducers: {
        newAlert(state: any, action: any) {
            const agentId = action.payload.sourceId;
            const title = action.payload.title;
            const image = action.payload.image;

            if (agentId && (title || image)) {
                state.newAlert = {
                    agent: agentId,
                    title: title,
                    image: image,
                };
                state.showAlert = true;
            }
        },
        changeShowAlertAction(state: any, action: any) {
            state.showAlert = action.payload;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(getAlerts.pending, (state: any) => {
            state.alertsLoading = true;
        });
        builder.addCase(getAlerts.fulfilled, (state: any, action: any) => {
            const alerts = action.payload.alerts;

            if (alerts.length) {
                state.afterId = alerts[alerts.length - 1].id;
                state.canLoadMore = alerts.length == alertsPerPage;
                state.alerts = alerts;
            } else {
                state.canLoadMore = false;
                state.afterId = -1;
            }

            state.alertsLoading = false;
        });
        builder.addCase(getAlerts.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
            state.alertsLoading = false;
        });

        builder.addCase(getMoreAlerts.fulfilled, (state: any, action: any) => {
            const alerts = action.payload.alerts;

            if (alerts.length) {
                state.afterId = alerts[alerts.length - 1].id;
                state.canLoadMore = alerts.length == alertsPerPage;
                state.alerts = [...state.alerts, ...alerts];
            } else {
                state.canLoadMore = false;
                state.afterId = -1;
            }
        });
        builder.addCase(getMoreAlerts.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
    },
});

export const { newAlert, changeShowAlertAction } = alertSlice.actions;

export default alertSlice.reducer;
