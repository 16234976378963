import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    getAgentLogsData as getAgentLogsDataApi,
    getAgentLogs as getAgentLogsApi,
} from '../../../helpers/backend_helper';

export const getAgentLogsData = createAsyncThunk(
    'agentLogs/getAgentLogsData',
    async ({ agentId, logsType }: any) => {
        try {
            const response = getAgentLogsDataApi(agentId, logsType);
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const getAgentLogs = createAsyncThunk(
    'agentLogs/getAgentLogs',
    async ({ agentId, logsType, logsName }: any) => {
        try {
            const response = getAgentLogsApi(agentId, logsType, logsName);
            return response;
        } catch (error) {
            return error;
        }
    }
);
