import { createSlice } from '@reduxjs/toolkit';

import { getAgents, setNotificationStatus } from './thunk';

export const initialState = {
    agents: [],
    loading: true,
    notificationStatLoading: false,
    error: {},
};

const agentSlice = createSlice({
    name: 'Agent',
    initialState,
    reducers: {
        updateAgent(state: any, action: any) {
            const agentId = action.payload.agentId;
            const data = action.payload.data;

            if (agentId && data) {
                state.agents = state.agents.map((agent: any) =>
                    agent.id === agentId ? { ...agent, ...data } : agent
                );
            }
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(getAgents.pending, (state: any) => {
            state.loading = true;
        });
        builder.addCase(getAgents.fulfilled, (state: any, action: any) => {
            const map = new Map(
                action.payload.map((item: any) => [item.id, item])
            );
            const newAgents = state.agents
                .filter((item: any) => map.has(item.id))
                .map((item: any) => {
                    const itemFromPayload = map.get(item.id);
                    return itemFromPayload
                        ? { ...item, ...itemFromPayload }
                        : item;
                });

            action.payload.forEach((item: any) => {
                if (!newAgents.some((i: any) => i.id === item.id)) {
                    newAgents.push(item);
                }
            });

            state.agents = newAgents;
            state.loading = false;
        });
        builder.addCase(getAgents.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
            state.loading = false;
        });

        builder.addCase(setNotificationStatus.pending, (state: any) => {
            state.notificationStatLoading = true;
        });
        builder.addCase(
            setNotificationStatus.fulfilled,
            (state: any, action: any) => {
                const agentId = Object.keys(action.payload)[0];

                state.agents = state.agents.map((agent: any) =>
                    agent.id === agentId
                        ? { ...agent, notification: action.payload[agentId] }
                        : agent
                );
                state.notificationStatLoading = false;
            }
        );
        builder.addCase(
            setNotificationStatus.rejected,
            (state: any, action: any) => {
                state.error = action.payload.error || null;
                state.notificationStatLoading = false;
            }
        );
    },
});

export const { updateAgent } = agentSlice.actions;

export default agentSlice.reducer;
