import { changeHTMLAttribute } from './utils';
import {
    changeLayoutAction,
    changeLayoutModeAction,
    changeSidebarHoverAction,
    changePreLoaderAction,
    changeSidebarVisibilityAction,
} from './reducer';

/**
 * Changes the layout type
 * @param {*} param0
 */
export const changeLayout = (layout: any) => async (dispatch: any) => {
    try {
        if (layout === 'horizontal') {
            document.documentElement.removeAttribute('data-sidebar-size');
        }
        changeHTMLAttribute('data-layout', layout);
        dispatch(changeLayoutAction(layout));
    } catch (error) {}
};

/**
 * Changes the layout mode
 * @param {*} param0
 */
export const changeLayoutMode = (layoutMode: any) => async (dispatch: any) => {
    try {
        changeHTMLAttribute('data-bs-theme', layoutMode);
        changeHTMLAttribute('data-sidebar', layoutMode);
        dispatch(changeLayoutModeAction(layoutMode));
    } catch (error) {}
};

/**
 * Changes the sidebar hover status
 * @param {*} param0
 */
export const changeSidebarHover = (action: any) => async (dispatch: any) => {
    try {
        dispatch(changeSidebarHoverAction(action));
    } catch (error) {
        // console.log(error);
    }
};

/**
 * Changes the Preloader
 * @param {*} param0
 */
export const changePreLoader =
    (preloaderTypes: any) => async (dispatch: any) => {
        try {
            changeHTMLAttribute('data-preloader', preloaderTypes);
            dispatch(changePreLoaderAction(preloaderTypes));
        } catch (error) {
            // console.log(error);
        }
    };

/**
 * Changes the sidebar visibility
 * @param {*} param0
 */
export const changeSidebarVisibility =
    (sidebarVisibilitytype: any) => async (dispatch: any) => {
        try {
            changeHTMLAttribute(
                'data-sidebar-visibility',
                sidebarVisibilitytype
            );
            dispatch(changeSidebarVisibilityAction(sidebarVisibilitytype));
        } catch (error) {}
    };
