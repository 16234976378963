import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    status: 'disconnected',
    error: null,
};

const webSocketSlice = createSlice({
    name: 'WebSocket',
    initialState,
    reducers: {
        connected: (state) => {
            state.status = 'connected';
            state.error = null;
        },
        disconnected: (state) => {
            state.status = 'disconnected';
        },
        errorOccurred: (state, action) => {
            state.status = 'disconnected';
            state.error = action.payload;
        },
    },
});

export const { connected, disconnected, errorOccurred } =
    webSocketSlice.actions;

export default webSocketSlice.reducer;
