import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Spinner,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import TableContainer from '../../Components/Common/TableContainerReactTable';
import { getInvoices } from '../../slices/thunks';

const Invoices = () => {
    const dispatch: any = useDispatch();

    const invoicesData = createSelector(
        (state: any) => state.Invoice,
        (state) => ({
            invoices: state.invoices,
            loading: state.loading,
        })
    );

    const { invoices, loading } = useSelector(invoicesData);

    useEffect(() => {
        dispatch(getInvoices());
    }, [dispatch]);

    const columns = useMemo(
        () => [
            {
                header: 'ID',
                cell: (cell: any) => {
                    return (
                        <Link
                            to={`/invoice/${cell.getValue()}`}
                            className="fw-medium"
                        >
                            {cell.getValue()}
                        </Link>
                    );
                },
                accessorKey: 'id',
                enableColumnFilter: false,
            },
            {
                header: 'Name',
                accessorKey: 'username',
                enableColumnFilter: false,
            },
            {
                header: 'Invoice Date',
                accessorKey: 'invoiceDate',
                enableColumnFilter: false,
            },
            {
                header: 'Amount',
                accessorKey: 'amount',
                enableColumnFilter: false,
                cell: (cell: any) => {
                    let value = 0;
                    cell.getValue().forEach((amount: number, index: number) => {
                        value += amount * cell.row.original.quantity[index];
                    });
                    value -= cell.row.original.discount;
                    value += (cell.row.original.taxPercentage / 100) * value;
                    return <span>{value}</span>;
                },
            },
            {
                header: 'Action',
                cell: (cell: any) => {
                    return (
                        <UncontrolledDropdown>
                            <DropdownToggle
                                href="#"
                                className="btn btn-soft-secondary btn-sm dropdown"
                                tag="button"
                            >
                                <i className="ri-more-fill align-middle"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                    href={`/invoice/${cell.row.original.id}`}
                                >
                                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{' '}
                                    View
                                </DropdownItem>

                                <DropdownItem href="#">
                                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{' '}
                                    Edit
                                </DropdownItem>

                                <DropdownItem href="#">
                                    <i className="ri-download-2-line align-bottom me-2 text-muted"></i>{' '}
                                    Download
                                </DropdownItem>

                                <DropdownItem divider />

                                <DropdownItem href="#" onClick={() => {}}>
                                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{' '}
                                    Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    );
                },
            },
        ],
        []
    );

    document.title = 'Invoices | MySpy';

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">
                                        Invoices
                                    </h5>
                                </CardHeader>
                                <CardBody className="pt-2">
                                    {loading ? (
                                        <div className="text-center">
                                            <Spinner
                                                animation="border"
                                                variant="primary"
                                            />
                                        </div>
                                    ) : (
                                        <TableContainer
                                            columns={columns || []}
                                            data={invoices || []}
                                            isGlobalFilter={true}
                                            customPageSize={20}
                                            divClass="table-responsive dropdown-responsive"
                                            tableClass="table-sm table-centered align-middle table-nowrap mb-0"
                                            theadClass="text-muted table-light"
                                            SearchPlaceholder="Search..."
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Invoices;
