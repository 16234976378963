import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import alertSound from '../../assets/audio/alert.mp3';

const AlertViewModal = ({
    isOpen,
    setToggle,
    title,
    desc,
    image,
    enableSound = true,
}: any): any => {
    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        if (!enableSound) {
            return;
        }

        if (isOpen && audioRef.current) {
            audioRef.current.play();
        }

        const currentAudioRef = audioRef.current;

        return () => {
            if (currentAudioRef) {
                currentAudioRef.pause();
                currentAudioRef.currentTime = 0;
            }
        };
    }, [isOpen, enableSound]);

    return (
        <React.Fragment>
            <audio ref={audioRef} src={alertSound} loop />
            <Modal
                size="xl"
                isOpen={isOpen}
                toggle={() => setToggle(!isOpen)}
                backdrop={'static'}
            >
                <ModalHeader toggle={() => setToggle(false)}>
                    {title}
                </ModalHeader>
                <ModalBody className="text-center p-5">
                    {image ? (
                        <img
                            src={`data:image/*;base64,${image}`}
                            style={{ width: '100%', height: '100%' }}
                        />
                    ) : null}

                    <div className="mt-4">
                        <h4 className="mb-3">{desc}</h4>
                        <div className="hstack gap-2 justify-content-center">
                            <Link
                                to="#"
                                className="btn btn-link link-success fw-medium"
                                onClick={() => setToggle(false)}
                            >
                                <i className="ri-close-line me-1 align-middle"></i>{' '}
                                Close
                            </Link>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default AlertViewModal;
