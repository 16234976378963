import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

const Navdata = () => {
    // const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState<boolean>(true);
    const [isAgents, setIsAgents] = useState<boolean>(true);
    const [isApps, setIsApps] = useState<boolean>(true);
    const [isOthers, setIsOthers] = useState<boolean>(true);

    // Apps
    const [isCRM, setIsCRM] = useState<boolean>(true);
    const [isInvoices, setIsInvoices] = useState<boolean>(true);
    const [isSupportTickets, setIsSupportTickets] = useState<boolean>(true);

    // Pages
    const [isProfile, setIsProfile] = useState<boolean>(true);

    // const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute('sub-items')) {
            const ul: any = document.getElementById('two-column-menu');
            const iconItems: any = ul.querySelectorAll('.nav-icon.active');
            const activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove('active');
                const id = item.getAttribute('sub-items');
                const getID = document.getElementById(id) as HTMLElement;
                if (getID) getID.classList.remove('show');
            });
        }
    }

    // useEffect(() => {
    //     if (iscurrentState !== 'Dashboard') {
    //         setIsDashboard(false);
    //     }
    //     if (iscurrentState !== 'Apps') {
    //         setIsApps(false);
    //     }
    //     if (iscurrentState !== 'Agents') {
    //         setIsAgents(false);
    //     }
    //     if (iscurrentState !== 'Others') {
    //         setIsOthers(false);
    //     }
    //     if (iscurrentState === 'Widgets') {
    //         history('/widgets');
    //     }
    // }, [history, iscurrentState, isDashboard, isApps, isOthers]);

    const menuItems: any = [
        {
            label: 'Menu',
            isHeader: true,
        },
        {
            id: 'dashboard',
            label: 'Dashboards',
            icon: 'ri-dashboard-2-line',
            link: '/#',
            stateVariables: isDashboard,
            click: function (e: any) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                // setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'main',
                    label: 'Main',
                    link: '/dashboard',
                    parentId: 'dashboard',
                },
            ],
        },
        {
            id: 'agents',
            label: 'Agents',
            icon: 'ri-server-line',
            link: '/#',
            stateVariables: isAgents,
            click: function (e: any) {
                e.preventDefault();
                setIsAgents(!isAgents);
                // setIscurrentState('Agents');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'agents_detail',
                    label: 'Agents',
                    link: '/agents',
                    parentId: 'agents',
                },
            ],
        },
        {
            id: 'apps',
            label: 'Apps',
            icon: 'ri-apps-2-line',
            link: '/#',
            stateVariables: isApps,
            click: function (e: any) {
                e.preventDefault();
                setIsApps(!isApps);
                // setIscurrentState('Apps');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'appscrm',
                    label: 'CRM',
                    link: '/#',
                    isChildItem: true,
                    click: function (e: any) {
                        e.preventDefault();
                        setIsCRM(!isCRM);
                    },
                    parentId: 'apps',
                    stateVariables: isCRM,
                    childItems: [
                        { id: 1, label: 'Customers', link: '/customers' },
                    ],
                },
                {
                    id: 'invoices',
                    label: 'Invoices',
                    link: '/#',
                    isChildItem: true,
                    click: function (e: any) {
                        e.preventDefault();
                        setIsInvoices(!isInvoices);
                    },
                    parentId: 'apps',
                    stateVariables: isInvoices,
                    childItems: [
                        { id: 1, label: 'Invoices', link: '/invoices' },
                    ],
                },
                {
                    id: 'supportTickets',
                    label: 'Support Tickets',
                    link: '/#',
                    isChildItem: true,
                    click: function (e: any) {
                        e.preventDefault();
                        setIsSupportTickets(!isSupportTickets);
                    },
                    parentId: 'apps',
                    stateVariables: isSupportTickets,
                    childItems: [
                        { id: 1, label: 'List View', link: '/' },
                        { id: 2, label: 'Ticket Details', link: '/' },
                    ],
                },
                {
                    id: 'filemanager',
                    label: 'File Manager',
                    link: '/',
                    parentId: 'apps',
                },
                {
                    id: 'todo',
                    label: 'To Do',
                    link: '/',
                    parentId: 'apps',
                },
                {
                    id: 'apikey',
                    label: 'API Key',
                    link: '/',
                    parentId: 'apps',
                },
            ],
        },
        {
            label: 'others',
            isHeader: true,
        },
        {
            id: 'others',
            label: 'Others',
            icon: 'ri-add-box-line',
            link: '/#',
            stateVariables: isOthers,
            click: function (e: any) {
                e.preventDefault();
                setIsOthers(!isOthers);
                // setIscurrentState('Others');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'profile',
                    label: 'Profile',
                    link: '/#',
                    isChildItem: true,
                    click: function (e: any) {
                        e.preventDefault();
                        setIsProfile(!isProfile);
                    },
                    parentId: 'others',
                    stateVariables: isProfile,
                    childItems: [
                        {
                            id: 1,
                            label: 'Edit profile',
                            link: '/',
                            parentId: 'others',
                        },
                        {
                            id: 2,
                            label: 'Settings',
                            link: '/',
                            parentId: 'others',
                        },
                    ],
                },
                { id: 'faqs', label: 'FAQs', link: '/', parentId: 'others' },
                {
                    id: 'PrivacyPolicy',
                    label: 'Privacy Policy',
                    link: '/',
                    parentId: 'others',
                },
                {
                    id: 'TermsCondition',
                    label: 'Terms Condition',
                    link: '/',
                    parentId: 'others',
                },
            ],
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
