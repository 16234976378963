import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
    Container,
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
    Spinner,
} from 'reactstrap';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { getCustomer } from '../../../slices/thunks';

const Customer = () => {
    const { id } = useParams();
    const dispatch: any = useDispatch();

    const customerData = createSelector(
        (state: any) => state.Customer,
        (state) => ({
            customer: state.customer,
            loading: state.customerLoading,
        })
    );

    const { customer, loading } = useSelector(customerData);

    useEffect(() => {
        if (id) {
            dispatch(getCustomer(id));
        }
    }, [dispatch, id]);

    document.title = 'Customer Summary | MySpy';

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        title={id}
                        breadcrumbs={[
                            { title: 'Dashboard', path: '/' },
                            { title: 'Customers', path: '/customers' },
                        ]}
                    />
                    <Card>
                        <CardHeader>
                            <b>Customer details for {id}</b>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                {loading ? (
                                    <div className="text-center">
                                        <Spinner
                                            animation="border"
                                            variant="primary"
                                        />
                                    </div>
                                ) : (
                                    customer &&
                                    Object.entries(customer).map(
                                        ([key, value]): any => (
                                            <Col
                                                xs="12"
                                                sm="6"
                                                lg="4"
                                                key={key}
                                                className="custom-col-3 mb-2"
                                            >
                                                <h6>
                                                    {key
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        key.slice(1)}
                                                </h6>
                                                {(() => {
                                                    switch (key) {
                                                        case 'room':
                                                            return (
                                                                <Link
                                                                    to={`/agent/${value}`}
                                                                    className="fw-medium"
                                                                >{`${value}`}</Link>
                                                            );
                                                        default:
                                                            return (
                                                                <p>{`${value}`}</p>
                                                            );
                                                    }
                                                })()}
                                            </Col>
                                        )
                                    )
                                )}
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Customer;
