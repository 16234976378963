import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { setAuthorization } from '../helpers/api_helper';
import { useDispatch } from 'react-redux';

import config from '../config';
import { useProfile } from '../Components/Hooks/UserHooks';
import { logoutUser } from '../slices/auth/login/thunk';
import { connectWebSocket, disconnectWebSocket } from '../slices/ws/thunk';

const AuthProtected = (props: any) => {
    const dispatch: any = useDispatch();
    const { userProfile, loading, token } = useProfile();

    useEffect(() => {
        if (userProfile && !loading && token) {
            setAuthorization(token);
            dispatch(connectWebSocket(config.ws.WS_URL));
        } else if (!userProfile && loading && !token) {
            dispatch(logoutUser());
            dispatch(disconnectWebSocket());
        }
    }, [token, userProfile, loading, dispatch]);

    /*
    Navigate is un-auth access protected routes via url
    */

    if (!userProfile && loading && !token) {
        return (
            <React.Fragment>
                <Navigate to={{ pathname: '/login' }} />
            </React.Fragment>
        );
    }

    return <>{props.children}</>;
};

export default AuthProtected;
